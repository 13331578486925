<template>
	<div class="radimix-teate-lisamine">
		<vk-button type="primary" @click="show_modal = true">Uus teade</vk-button>

		<vk-modal :show.sync="show_modal" size="uk-width-1-1" :stuck="true">
	  			<vk-modal-close @click="show_modal = false"></vk-modal-close>
	  			<vk-modal-title slot="header">Uue lingi lisamine</vk-modal-title>
	  			<p>
	    			Siin saad lisada uut teadet veebilehe, midagi ajakohast mis peaksid õpilased või veebilehe külastajad esimesena teadma.
	  			</p>
				<form class="uk-form-stacked">

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Teate sisu</label>
	        			<div class="uk-form-controls">

	        				<vue-editor v-model.lazy="teate_sisu"></vue-editor>
	        			</div>
	    			</div>
				</form>

	  			<div slot="footer" class="uk-text-right">
	    			<vk-button type="danger" class="uk-margin-small-right" @click="show_modal = false">Tühista</vk-button>
	    			<vk-button type="primary" class="uk-margin-small-right" @click="salvesta();">Salvesta</vk-button>
	  			</div>
		</vk-modal>
	</div>
</template>

<script>
	import { VueEditor } from "vue2-editor";
	
	export default {
		data() {
			return {
				show_modal: false,

				teate_sisu: null
			}
		},

		methods: {
			salvesta() {
				this.show_modal = false

				this.$emit("submit", {
					teate_sisu: this.teate_sisu
				})
			}

		},

		components: {
			VueEditor
		}
	}
</script>