<template>
	<div class="radimix_moodul_pealeht">
		<vk-button class="uk-width-1-4" @click="show_modal = true" type="primary">Lisa kasutaja</vk-button>
		<vk-modal :show.sync="show_modal" size="uk-width-1-1">
	  			<vk-modal-close @click="show_modal = false"></vk-modal-close>
	  			<vk-modal-title slot="header">Uue kasutaja lisamine</vk-modal-title>
				<form class="uk-form-stacked">
	  				<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Kasutajanimi</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="kasutajanimi" type="text">
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Eesnimi</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="eesnimi" type="text">
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Perekonnanimi</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="perekonnanimi" type="text">
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Parool</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="parool" type="password">
	        			</div>
	    			</div>
				</form>

	  			<div slot="footer" class="uk-text-right">
	    			<vk-button class="uk-margin-small-right" @click="show_modal = false">Tühista</vk-button>
	    			<vk-button class="uk-margin-small-right" @click="registeeru();">Registeeru</vk-button>
	  			</div>
		</vk-modal>
	</div>
</template>

<script>
	
	export default {
		data() {
			return {
				show_modal: false,

				kasutajanimi: null,
				eesnimi: null,
				perekonnanimi: null,
				parool: null

			}
		},

		methods: {
			async registeeru() {
				this.show_modal = false;
				this.$parent.registeeri_uus_kasutaja(this.kasutajanimi, this.eesnimi, this.perekonnanimi, this.parool)
			}
		}
	}
</script>