<template>
	<div class="radimix-galerii">
		<div class="uk-margin" uk-margin>
			<div uk-form-custom="target: true">
				<input type="file" ref="file" id="file" @change="handleFileUpload">
				<input class="uk-input uk-form-width-medium" type="text" placeholder="Valitud pilt" disabled>
				<button class="uk-button uk-button-default" type="button" tabindex="-1">Vali pilt</button>
			</div>

			<button class="uk-button uk-button-default" @click="uploadImage" type="button">Lae pilt serveri</button>

			<hr class="uk-divider-icon">
			<vk-grid class="uk-child-width-1-4@m">
			  <div class="radimix-pildid" v-for="item in this.pildid" :key="item['pildi_id']">
			    <vk-card hover>
			      <div slot="media-top">
			        <img v-bind:src="item['pildi_url']" alt="">
			      </div>
			      <div>
        				<button class="uk-button uk-button-danger uk-width-1-1" type="button">Kustuta</button>
      				</div>
			    </vk-card>
			  </div>
			</vk-grid>
		</div>
	</div>
</template>



<script>
	import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';

	export default {

		data() {
			return {
				file: '',
				loading: true,
				pildid: []
			}
		},

		methods: {
			handleFileUpload() {
				this.file = this.$refs.file.files[0]
			},

			async uploadImage() {
				let formData = new FormData();
				formData.append('file', this.file)

				console.log('FORM: ' + formData)
				const sisu_moodul = getModule(SisuMoodul, this.$store)
				await sisu_moodul.lae_pilt(formData)

				await sisu_moodul.koik_pildid()

				this.pildid = sisu_moodul.pildid
			}
		},

		async mounted() {
				const sisu_moodul = getModule(SisuMoodul, this.$store)
				await sisu_moodul.koik_pildid()

				this.pildid = sisu_moodul.pildid

				console.log(sisu_moodul.pildid)

				this.loading = false

			}
	}
</script>