<template>
	<div class="radimix-sisuhaldus-info-kastid uk-overflow-auto">
		<table class="uk-table uk-table-striped" v-if="loaded">
   			<thead>
        		<tr>
            		<th>Pealkiri</th>
            		<th>Sisu</th>
            		<th>Valikud</th>
        		</tr>
    		</thead>
    		<tbody>
       			<tr v-for="item in this.info_kastid" :key="item['info_id']">
            		<td v-if="item['info_tyyp'] == 'info'" width="20%">{{ item['pealkiri'] }}</td>
            		<td v-if="item['info_tyyp'] == 'info'"  width="20%" v-html="item['sisu']"></td>
            		<td width="10%">
    					  		<MuudaInfo 
                    @submit="muuda_info_kasti($event);"
                    :id="item['info_id']"
                    :pealkiri="item['pealkiri']"
                    :sisu="item['sisu']"
                    :badge="item['badge']"/>
					  		   <vk-button type="danger" class="uk-width-1-1" @click="kustuta_info_kast(item['info_id'])">Kustuta</vk-button>
            		</td>
        		</tr>
    		</tbody>
    	</table>

    	<LisaInfo/>

	</div>
</template>

<script>
	
	  import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';

  	import LisaInfo from '@/components/administraator/sisuhaldus/moodulid/moodul_lisa_info.vue'
    import MuudaInfo from '@/components/administraator/sisuhaldus/moodulid/moodul_muuda_info.vue'

  	export default {

  		data() {
  			return {
  				info_kastid: null,
  				loaded: false
  			}
  		},

  		components: {
  			LisaInfo,
        MuudaInfo
  		},

  		methods: {
  			async lisa_uus_info(tyyp, pealkiri, sisu, badge, pilt) {
  				this.loaded = false
  				const sisu_moodul = getModule(SisuMoodul, this.$store)

  				await sisu_moodul.set_info({
  					info_tyyp: tyyp,
  					pealkiri: pealkiri, 
  					sisu: sisu, 
  					pilt: pilt, 
  					badge: badge
  				})

  				await sisu_moodul.get_info_kastid()

  				this.info_kastid = sisu_moodul.info_kastid
  				this.loaded = true

    		  },

          async muuda_info_kasti(data) {
            const sisu_moodul = getModule(SisuMoodul, this.$store)

            await sisu_moodul.modify_info({
              info_id: data.id,
              info_tyyp: data.tyyp,
              pealkiri: data.pealkiri, 
              sisu: data.sisu, 
              pilt: data.pilt, 
              badge: data.badge
            })

            await sisu_moodul.get_info_kastid()

            this.info_kastid = sisu_moodul.info_kastid
          },

          async kustuta_info_kast(info_id) {
            this.loaded = false
            const sisu_moodul = getModule(SisuMoodul, this.$store)

            await sisu_moodul.delete_info(info_id)

            await sisu_moodul.get_info_kastid()

            this.info_kastid = sisu_moodul.info_kastid
            this.loaded = true
          }
      },

  		mounted() {
  			const sisu_moodul = getModule(SisuMoodul, this.$store)

  			this.info_kastid = sisu_moodul.info_kastid

  			this.loaded = true


  		}
  	}

</script>