<template>
	<div class="radimix-lisa-info-moodul">
		<vk-button type="primary" @click="show_modal = true">Lisa uus informatsioon</vk-button>

		<vk-modal :show.sync="show_modal" size="uk-width-1-1" :stuck="true">
	  			<vk-modal-close @click="show_modal = false"></vk-modal-close>
	  			<vk-modal-title slot="header">Uue informatsiooni kasti lisamine veebilehele</vk-modal-title>
	  			<p>
	    			Siin saad kirjutada sisu mida soovid lisada veebilehele, veebilehel kuvatakse seda uue kategooriana pealdise all.
	  			</p>
				<form class="uk-form-stacked">
	  				<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Pealkiri</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="pealkiri" id="form-stacked-text" type="text">
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Sisu</label>
	        			<div class="uk-form-controls">
	        				<vue-editor v-model.lazy="sisu"></vue-editor>
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Märk, kui ei soovi lisada märki võid jätta välja tühjaks</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="badge" id="form-stacked-text" type="text">
	        			</div>
	    			</div>
				</form>

	  			<div slot="footer" class="uk-text-right">
	    			<vk-button type="danger" class="uk-margin-small-right" @click="show_modal = false">Tühista</vk-button>
	    			<vk-button type="primary" class="uk-margin-small-right" @click="salvesta();">Salvesta</vk-button>
	  			</div>
		</vk-modal>

	</div>
</template>

<script>

	import { VueEditor } from "vue2-editor";

	export default {
		data() {
			return {
				show_modal: false,

				pealkiri: null,
				sisu: null,
				badge: ''
			}
		},

		components: {
			VueEditor
		},

		methods: {
			salvesta() {

				this.show_modal = false

				this.$parent.lisa_uus_info('info', this.pealkiri, this.sisu, '', this.badge);

			}
		}
	}
	
</script>