<template>
	<div class="radimix-sisuhaldus-pealeht">
		<div v-if="this.state == 3" class="uk-alert-success" uk-alert>
    		<p>{{ this.message }}</p>
		</div>
		<h4>Pealdise informatsioon</h4>
		<table class="uk-table uk-table-striped">
   			<thead>
        		<tr>
            		<th>Pealkiri</th>
            		<th>Sisu</th>
            		<th>Valikud</th>
        		</tr>
    		</thead>
    		<tbody v-if="this.state != 1">
       			<tr v-for="item in this.pealdis" :key="item['pealdis_id']">
            		<td width="20%">{{ item['pealkiri'] }}</td>
            		<td width="20%" v-html="item['sisu']"></td>
            		<td width="20%"><MuudaMoodul 
            			:pealkiri="item['pealkiri']"
            			:sisu="item['sisu']"
            			:badge="item['badge']"
            			:pilt="item['pilt']"
            			/>
            		</td>
        		</tr>
    		</tbody>
    		<tbody v-else>
    			<div uk-spinner></div></tbody>
    	</table>
    	<Teated/>
	</div>
</template>

<script>

	import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';

  	import MuudaMoodul from '@/components/administraator/sisuhaldus/moodulid/moodul_pealeht.vue'
  	import Teated from '@/components/administraator/sisuhaldus/teated.vue'

	export default {
		data() {
			return {
				pealdis: null,
				state: null,
				message: null

			}
		},

		components: {
			MuudaMoodul,
			Teated
		},

		methods: {
			async update_sisu(pealkiri, sisu, pilt, badge) {
				const sisu_moodul = getModule(SisuMoodul, this.$store)

				await sisu_moodul.set_pealdis({
					pealkiri: pealkiri, 
					sisu: sisu, 
					pilt: pilt, 
					badge: badge
				})

				await sisu_moodul.get_pealdis()

				this.pealdis = sisu_moodul.pealdis
				this.state = sisu_moodul.sisu_state
				this.message = sisu_moodul.sisu_message
			}
		},

		mounted() {
			this.pealdis = getModule(SisuMoodul, this.$store).pealdis
		}
	}
</script>

<style scoped>
.uk-button-green {
	background-color: #3b8222;
}
</style>