<template>
	<div class="radimix_info_muutmine">
		<vk-button class="uk-width-1-1" @click="show_modal = true" type="primary">Muuda</vk-button>
		<vk-modal :show.sync="show_modal" size="uk-width-1-1" :stuck="true">
	  			<vk-modal-close @click="show_modal = false"></vk-modal-close>
	  			<vk-modal-title slot="header">Muuda informatsiooni</vk-modal-title>
	  			<p>
	    			Siin saad muuta informatsiooni mis on kuvatud pealehel, pärast allpool näidatud lahtreid vajuta "Salvesta"
	  			</p>
				<form class="uk-form-stacked">
	  				<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Pealkiri</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="m_pealkiri" id="form-stacked-text" type="text">
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Sisu</label>
	        			<div class="uk-form-controls">

	        				<vue-editor v-model="m_sisu"></vue-editor>
	        			</div>
	    			</div>
	    			
	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Badge</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="m_badge" id="form-stacked-text" type="text">
	        			</div>
	    			</div>
				</form>

	  			<div slot="footer" class="uk-text-right">
	    			<vk-button class="uk-margin-small-right" @click="show_modal = false">Tühista</vk-button>
	    			<vk-button class="uk-margin-small-right" @click="salvesta();">Salvesta</vk-button>
	  			</div>
		</vk-modal>
	</div>
</template>

<script>

	import { VueEditor } from "vue2-editor";

	export default {
		data() {
			return {
				show_modal: false,

				m_id: this.id,
				m_pealkiri: this.pealkiri,
				m_sisu: this.sisu,
				m_badge: this.badge

			}
		},

		methods: {
			salvesta() {
				this.show_modal = false

				this.$emit("submit", {
					tyyp: 'info',
					id: this.m_id,
					pealkiri: this.m_pealkiri,
					sisu: this.m_sisu,
					badge: '',
					pilt: ''
				})
			}
		},

		components: {
    		VueEditor
  		},

		props: [
			'id',
			'pealkiri',
			'sisu',
			'badge'
		]
	}
</script>

<style scoped>

.uk-button-green {
	background-color: #3b8222;
	color: white;
}
</style>