import { render, staticRenderFns } from "./opilaste_nimekiri.vue?vue&type=template&id=11f394b4&"
import script from "./opilaste_nimekiri.vue?vue&type=script&lang=js&"
export * from "./opilaste_nimekiri.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports