<template>
	<div class="radimix-teated">
		<h4>Teadete lisamine/eemaldamine</h4>

		<table class="uk-table uk-table-striped">
   			<thead>
        		<tr>
            		<th>Teade</th>
            		<th>Valikud</th>
        		</tr>
    		</thead>
    		<tbody v-if="loading == false">
       			<tr v-for="teade in this.teated" :key="teade['teate_id']">
            		<td width="70%" v-html="teade['teade']"></td>
            		<td width="30%">
            			<vk-button type="danger" class="uk-width-1-1" @click="kustuta_teadis(teade['teate_id'])">Kustuta</vk-button>

            		</td>
        		</tr>
    		</tbody>
    	</table>

    	<TeateLisamine @submit="lisa_uus_teade($event);"/>
	</div>
</template>

<script>

	import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';

  	import TeateLisamine from '@/components/administraator/sisuhaldus/moodulid/teate_lisamine.vue'
	
	export default {
		data() {
			return {
				loading: false,
				teated: null
			}
		},

		components: {
			TeateLisamine,
		},

		methods: {
			async lisa_uus_teade(data) {
				this.loading = true

				const sisu_moodul = getModule(SisuMoodul, this.$store)
				
				await sisu_moodul.lisa_teade(data.teate_sisu)
				await sisu_moodul.get_teated()

				this.teated = sisu_moodul.teated

				this.loading = false
			},

			async kustuta_teadis(id) {
				this.loading = true

				const sisu_moodul = getModule(SisuMoodul, this.$store)
				await sisu_moodul.kustuta_teade(id)
				await sisu_moodul.get_teated()

				this.teated = sisu_moodul.teated

				this.loading = false
			}
		},

		mounted() {
			this.loading = true

			const sisu_moodul = getModule(SisuMoodul, this.$store)
			this.teated = sisu_moodul.teated

			this.loading = false

		}

	}
</script>