<template>
	<div class="radimix-sisuhaldus-lingid">
		<h4>Linkide lisamine ja muutmine</h4>
		<div v-if="loading == false" class="koik_lingid">
			<table class="uk-table uk-table-striped">
	   			<thead>
	        		<tr>
	            		<th>Pealkiri</th>
	            		<th>Sisu</th>
	            		<th>CTA</th>
	            		<th>Värv</th>
	            		<th>Href</th>
	            		<th>Valikud</th>
	        		</tr>
	    		</thead>
	    		<tbody>
	       			<tr v-for="link in this.k_lingid" :key="link['lingi_id']">
	            		<td width="20%">{{ link['lingi_pealkiri'] }}</td>
	            		<td width="30%" v-html="link['lingi_sisu']"></td>
	            		<td>{{ link['lingi_cta'] }}</td>
	            		<td>{{ link['lingid_cta_color'] }}</td>
	            		<td>{{ link['lingid_cta_href'] }}</td>
	            		<td>
	            			<LingiMuutmine
	            				@submit="muuda_link($event);"

	            				:lingi_id="link['lingi_id']"
								:lingi_content_id="link['lingi_content_id']"
								:lingi_pealkiri="link['lingi_pealkiri']"
								:lingi_sisu="link['lingi_sisu']"
								:lingi_cta="link['lingi_cta']"
								:lingid_cta_color="link['lingid_cta_color']"
								:lingid_cta_href="link['lingid_cta_href']"/>

							<vk-button type="danger" class="uk-width-1-1" @click="kustuta_link(link['lingi_id'])">Kustuta</vk-button>
	            		</td>
	        		</tr>
	    		</tbody>
    		</table>
		</div>
		<hr>
		<LingiLisamine @submit="lisa_link($event);"/>
	</div>
</template>

<script>
	import LingiLisamine from '@/components/administraator/sisuhaldus/moodulid/lingid_lisamine.vue'
	import LingiMuutmine from '@/components/administraator/sisuhaldus/moodulid/lingid_muuda.vue'

	import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';
	
	export default {

		data() {
			return {
				loading: false,
				k_lingid: null
			}
		},

		components: {
			LingiLisamine,
			LingiMuutmine
		},

		methods: {
			async lisa_link(data) {
				const sisu_moodul = getModule(SisuMoodul, this.$store)

				await sisu_moodul.lingi_lisamine(data)
				await sisu_moodul.get_lingid()

				this.k_lingid = sisu_moodul.lingid
			},

			async muuda_link(data) {
				const sisu_moodul = getModule(SisuMoodul, this.$store)

				await sisu_moodul.lingi_muutmine(data)
				await sisu_moodul.get_lingid()

				this.k_lingid = sisu_moodul.lingid
			},

			async kustuta_link(id) {
				const sisu_moodul = getModule(SisuMoodul, this.$store)

				await sisu_moodul.lingi_kustutamine(id)
				await sisu_moodul.get_lingid()

				this.k_lingid = sisu_moodul.lingid

			}
		},

		mounted() {
			this.loading = true
			const sisu_moodul = getModule(SisuMoodul, this.$store)
			this.k_lingid = sisu_moodul.lingid
			this.loading = false

		}

	}
</script>