<template>
	<div class="sisuhaldus uk-padding-small uk-height" style="height: 100%;">
		<vk-card class="uk-width-1-1@m">
  			<vk-card-title>Õpilaste nimekiri</vk-card-title>
  			<vk-tabs-vertical align="left">
		      <vk-tabs-item title="Uued õpilased">
		      	<OpilasteNimekiri
		      		pealkiri="Uued õpilased kes registeerunud"
		      		staatus="0"/>

		      </vk-tabs-item>
		      <vk-tabs-item title="Aktiivsed õpilased">
		      	<OpilasteNimekiri
		      		pealkiri="Aktiivsed õpilased"
		      		staatus="1"/>

		      </vk-tabs-item>
		      <vk-tabs-item title="Õpilaste arhiiv">
		      	<OpilasteNimekiri
		      		pealkiri="Õpilaste arhiiv"
		      		staatus="2"/>
		      </vk-tabs-item>
		      <vk-tabs-item title="Saadetud küsimused">
		      	<KysimusteNimekiri
		      		pealkiri="Saadetud küsimused autokoolile"
		      		staatus="0"/>
		      </vk-tabs-item>
		      <vk-tabs-item title="Arhiivitud küsimused">
		      	<KysimusteNimekiri
		      		pealkiri="Küsimuste arhiiv"
		      		staatus="1"/>
		      </vk-tabs-item>
		    </vk-tabs-vertical>
		</vk-card>
	</div>
</template>
<script>

  	import OpilasteNimekiri from '@/components/administraator/opilased/opilaste_nimekiri.vue'
  	import KysimusteNimekiri from '@/components/administraator/opilased/opilase_kysimused.vue'
	
	export default {
		components: {
			OpilasteNimekiri,
			KysimusteNimekiri
		}
	}
</script>