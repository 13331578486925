<template>
	<div class="opetajad uk-padding uk-padding-small uk-height" style="height: 100%;">
		<vk-card class="uk-width-1-1@m">
  			<vk-card-title>Õpetajad</vk-card-title>
  			<table class="uk-table uk-table-striped">
	   			<thead>
	        		<tr>
	            		<th>Kasutajanimi</th>
	            		<th>Eesnimi</th>
	            		<th>Perekonnanimi</th>
	            		<th>Valikud</th>
	        		</tr>
	    		</thead>
	    		<tbody>
       			<tr v-for="opetaja in this.opetajad" :key="opetaja['opetaja_id']">
            		<td>{{ opetaja['kasutajanimi'] }}</td>
            		<td>{{ opetaja['eesnimi'] }}</td>
            		<td>{{ opetaja['perekonnanimi'] }}</td>
            		<td><vk-button class="uk-width-1-1" @click="kustuta_kasutaja(opetaja['kasutajanimi']);" type="danger">Kustuta</vk-button></td>
        		</tr>
    		</tbody>
    		</table>

    		<LisaKasutaja/>
		</vk-card>
	</div>
</template>

<script>
	
	import { getModule } from 'vuex-module-decorators';
  	import LoginModule from '@/store/modules/radimix_login'

  	import LisaKasutaja from '@/components/administraator/opetajad/lisa_kasutaja.vue'


	export default {
		data() {
			return {
				opetajad: []
			}
		},

		methods: {

			async registeeri_uus_kasutaja(kasutajanimi, eesnimi, perekonnanimi, parool) {
				await getModule(LoginModule, this.$store).register({
					kasutajanimi: kasutajanimi,
					eesnimi: eesnimi,
					perekonnanimi: perekonnanimi,
					parool: parool
				})

				await getModule(LoginModule, this.$store).get_opetajad()
				this.opetajad = getModule(LoginModule, this.$store).opetajad['data']
			},

			async kustuta_kasutaja(kasutajanimi) {
				await getModule(LoginModule, this.$store).delete(kasutajanimi)

				await getModule(LoginModule, this.$store).get_opetajad()
				this.opetajad = getModule(LoginModule, this.$store).opetajad['data']
			}

		},

		mounted() {
			this.opetajad = getModule(LoginModule, this.$store).opetajad['data']
		},

		components: {
			LisaKasutaja
		}

	}
</script>