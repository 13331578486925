<template>
	<div class="radimix-opilase-info">
		<vk-button type="primary" @click="show_modal = true" class="uk-width-1-1">Vaata</vk-button>
		<vk-modal :show.sync="show_modal">
	  			<vk-modal-close @click="show_modal = false"></vk-modal-close>
	  			<vk-modal-title slot="header">Õpilane</vk-modal-title>
				
				<div class="radimix-opilase-info-sisu">
					<ul class="uk-list uk-list-divider">
					    <li><b>Email</b>: {{ i_email }}</li>
					    <li><b>Ees- ja perekonnanimi</b>: {{ i_eesnimi }} {{ i_perekonnanimi }}</li>
					    <li><b>Isikukood</b>: {{ i_isikukood }}</li>
					    <li><b>Address</b>: {{ i_address }}</li>
					    <li><b>Telefon</b>: {{ i_telefon }}</li>
					</ul>
					<hr>
					<b>Lisa informatsioon</b>
					<div uk-alert>{{ i_lisa_informatsioon }}</div>
				</div>

	  			<div slot="footer" class="uk-text-right">
	    			<vk-button class="uk-margin-small-right" @click="show_modal = false">Sulge</vk-button>
	  			</div>
		</vk-modal>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				show_modal: false,
				i_email: this.email,
				i_eesnimi: this.eesnimi,
				i_perekonnanimi: this.perekonnanimi,
				i_isikukood: this.isikukood,
				i_address: this.address,
				i_telefon: this.telefon,
				i_lisa_informatsioon: this.lisa_informatsioon,
				i_staatus: this.staatus
			}
		},

		props: [
			'email',
			'eesnimi',
			'perekonnanimi',
			'isikukood',
			'address',
			'telefon',
			'lisa_informatsioon',
			'staatus'
		]
	}
</script>