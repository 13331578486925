<template>
	<div class="radimix-administraator-heading uk-padding uk-background-secondary uk-light">
		<vk-grid class="uk-child-width-expand@s">
			<h1 class="uk-heading-bullet">Radimix OÜ</h1>
			<div class="uk-width-1-6 uk-align-right">
					<button class="uk-width-1-1 uk-button uk-button-danger" @click="logout()">Logi välja</button>
			</div>
		</vk-grid>
	</div>
</template>

<script>

	import { getModule } from 'vuex-module-decorators';
	import RegModul from '@/store/modules/radimix_login';

	export default {

		methods: {
			async logout() {
				await getModule(RegModul, this.$store).logout();
			}
		}
	}
</script>