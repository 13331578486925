<template>
	<div class="radimix-lingid-muuda">
		<vk-button class="uk-width-1-1" @click="show_modal = true" type="primary">Muuda</vk-button>

		<vk-modal :show.sync="show_modal" size="uk-width-1-1" :stuck="true">
	  			<vk-modal-close @click="show_modal = false"></vk-modal-close>
	  			<vk-modal-title slot="header">Muuda lingi sisu</vk-modal-title>
	  			<p>
	    			Siin saad muuta lingi sisu mis kuvatakse pealdise all.
	  			</p>
				<form class="uk-form-stacked">
	    			<div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
			            <label><input class="uk-radio" type="radio" v-model="m_lingi_content_id" value="0" checked> Tavaline</label>
			            <label><input class="uk-radio" type="radio" v-model="m_lingi_content_id" value="1"> Registeerimine</label>
			            <label><input class="uk-radio" type="radio" v-model="m_lingi_content_id" value="2"> Kontakt</label>
			            <label><input class="uk-radio" type="radio" v-model="m_lingi_content_id" value="3"> Suunamine</label>
			        </div>


	  				<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Pealkiri</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" id="form-stacked-text" v-model.lazy="m_lingi_pealkiri" type="text">
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Sisu</label>
	        			<div class="uk-form-controls">

	        				<vue-editor v-model.lazy="m_lingi_sisu"></vue-editor>
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Nupp</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" id="form-stacked-text" type="text" v-model.lazy="m_lingi_cta">
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Värv</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" id="form-stacked-text" type="text" v-model.lazy="m_lingid_cta_color">
	        			</div>
	    			</div>

	    			<div class="uk-margin" v-if="m_lingi_content_id == 3">
	        			<label class="uk-form-label" for="form-stacked-text">Suunamise link</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" id="form-stacked-text" type="text" v-model.lazy="m_lingid_cta_href">
	        			</div>
	    			</div>

				</form>

	  			<div slot="footer" class="uk-text-right">
	    			<vk-button type="danger" class="uk-margin-small-right" @click="show_modal = false">Tühista</vk-button>
	    			<vk-button type="primary" class="uk-margin-small-right" @click="salvesta();">Salvesta</vk-button>
	  			</div>
		</vk-modal>

	</div>

</template>

<script>

	import { VueEditor } from "vue2-editor";
	
	export default {

		data() {
			return {
				show_modal: false,

				m_lingi_id: this.lingi_id,
				m_lingi_content_id: this.lingi_content_id,
				m_lingi_pealkiri: this.lingi_pealkiri,
				m_lingi_sisu: this.lingi_sisu,
				m_lingi_cta: this.lingi_cta,
				m_lingid_cta_color: this.lingid_cta_color,
				m_lingid_cta_href: this.lingid_cta_href
			}
		},

		props: [
			'lingi_id',
			'lingi_content_id',
			'lingi_pealkiri',
			'lingi_sisu',
			'lingi_cta',
			'lingid_cta_color',
			'lingid_cta_href'
		],

		methods: {
			salvesta() {
				this.show_modal = false

				this.$emit("submit", {
					lingi_id: this.m_lingi_id,
					lingi_content_id: this.m_lingi_content_id,
					lingi_pealkiri:this.m_lingi_pealkiri,
					lingi_sisu:this.m_lingi_sisu,
					lingi_cta:this.m_lingi_cta,
					lingid_cta_color:this.m_lingid_cta_color,
					lingid_cta_href:this.m_lingid_cta_href
				})
			}
		},

		components: {
			VueEditor
		}
	}
</script>