<template>
	<div class="radimix-opilaste-nimekiri">
		<h4>{{ o_pealkiri }}</h4>

		<table v-if="loading == false" class="uk-table uk-table-striped">
   			<thead>
        		<tr>
            		<th>Email</th>
            		<th>Eesnimi</th>
            		<th>Perekonnanimi</th>
            		<th>Isikukood</th>
            		<th>Valikud</th>
        		</tr>
    		</thead>
    		<tbody>
    			<tr v-for="opilane in this.opilased" :key="opilane['opilased_id']">
    				<td v-if="opilane['staatus'] == o_staatus" width="20%">{{ opilane['email'] }}</td>
	            	<td v-if="opilane['staatus'] == o_staatus" width="20%">{{ opilane['eesnimi'] }}</td>
	            	<td v-if="opilane['staatus'] == o_staatus" width="20%">{{ opilane['perekonnanimi'] }}</td>
	            	<td v-if="opilane['staatus'] == o_staatus" width="20%">{{ opilane['isikukood'] }}</td>
	            	<td v-if="opilane['staatus'] == o_staatus" width="20%">	
	            		<OpilaseInfo 
	            			:email="opilane['email']"
							:eesnimi="opilane['eesnimi']"
							:perekonnanimi="opilane['perekonnanimi']"
							:isikukood="opilane['isikukood']"
							:address="opilane['address']"
							:telefon="opilane['telefon']"
							:lisa_informatsioon="opilane['lisa_informatsioon']"
							:staatus="opilane['staatus']"
	            		/>      
	            		<vk-button v-if="opilane['staatus'] == 0 || opilane['staatus'] == 2" class="uk-width-1-1 uk-margin uk-button-primary" style="background-color: darkgreen;" @click="aktiveeri(opilane['opilased_id'], 1)">Aktiveeri</vk-button>

	            		<vk-button v-if="opilane['staatus'] == 1 " class="uk-width-1-1 uk-margin uk-button-primary" style="background-color: darkorange;" @click="aktiveeri(opilane['opilased_id'], 2)">Arhiveeri</vk-button>
	            	</td>
    			</tr>
    		</tbody>
    	</table>
		
	</div>
</template>

<script>
	import OpilaseInfo from '@/components/administraator/opilased/opilase_info.vue'

	import { getModule } from 'vuex-module-decorators';
  	import LoginModule from '@/store/modules/radimix_login'

	export default {
		data() {
			return {
				o_pealkiri: this.pealkiri,
				o_staatus: this.staatus,
				opilased: null,
				loading: false
			}
		},

		props: [
			'pealkiri',
			'staatus'
		],

		methods: {
			async aktiveeri(id, staatus) {
				this.loading = true
				await getModule(LoginModule, this.$store).muuda_opilase_staatus({
					opilase_id: id,
					staatus: staatus
				})

				await getModule(LoginModule, this.$store).get_reg_opilased()
				this.opilased = getModule(LoginModule, this.$store).opilased['data']
				this.loading = false
			}
		},

		components: {
			OpilaseInfo
		},

		mounted() {
			this.loading = true
			this.opilased = getModule(LoginModule, this.$store).opilased['data']
			this.loading = false
		}
	}
</script>