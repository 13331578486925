<template>
	<div v-if="loading == false" class="radimix-administraator">
		<Heading/>
		<vk-tabs align="justify" class="uk-background-default uk-margin-remove-bottom">
		  <vk-tabs-item title="Sisuhaldus" class="uk-background-muted"><Sisuhaldus/></vk-tabs-item>
		  <vk-tabs-item title="Õpilased" class="uk-background-muted"><Opilased/></vk-tabs-item>
		  <vk-tabs-item title="Õpetajad" class="uk-background-muted"><Opetajad/></vk-tabs-item>
		</vk-tabs>
	</div>

	<div v-else>
		<vk-spinner ratio="3"></vk-spinner>
	</div>
</template>

<script>
	import Heading from '@/components/administraator/heading.vue'
	import Sisuhaldus from '@/components/administraator/sisuhaldus.vue'
	import Opilased from '@/components/administraator/opilased.vue'
	import Opetajad from '@/components/administraator/opetajad.vue'
	//import Arendus from '@/components/administraator/arendus.vue'

	import { getModule } from 'vuex-module-decorators';
  	import SisuMoodul from '@/store/modules/radimix_sisu';
  	import LoginModule from '@/store/modules/radimix_login'

	export default {
		data() {
			return {
				loading: true
			}
		},

		components: {
			Heading,
			Sisuhaldus,
			Opilased,
			Opetajad
		},

		async mounted() {
			const sisu_moodul = getModule(SisuMoodul, this.$store)
			const admin_moodul = getModule(LoginModule, this.$store)

			await sisu_moodul.get_info_kastid()
			await sisu_moodul.get_pealdis()
			await admin_moodul.get_opetajad()
			await admin_moodul.get_reg_opilased()
			await admin_moodul.vaata_kysimusi()
			await sisu_moodul.get_lingid()
			await sisu_moodul.get_teated()

			this.loading = false
		}
	}

</script>

<style>
ul {
    margin: 0 0 0 0;
}
</style>
