<template>
	<div class="radimix_moodul_pealeht">
		<vk-button class="uk-width-1-1" @click="show_modal = true" type="primary">Sisu muutmine</vk-button>
		<vk-modal :show.sync="show_modal" size="uk-width-1-1">
	  			<vk-modal-close @click="show_modal = false" :stuck="true"></vk-modal-close>
	  			<vk-modal-title slot="header">Pealehe informatsiooni muutmine</vk-modal-title>
	  			<p>
	    			Siin saad muuta informatsiooni mis on kuvatud pealehel, pärast allpool näidatud lahtreid vajuta "Salvesta"
	  			</p>
				<form class="uk-form-stacked">
	  				<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Pealkiri</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="m_pealkiri" id="form-stacked-text" type="text">
	        			</div>
	    			</div>

	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Sisu</label>
	        			<div class="uk-form-controls">

	        				<vue-editor v-model.lazy="m_sisu"></vue-editor>
	        			</div>
	    			</div>
	    			
	    			<div class="uk-margin">
	        			<label class="uk-form-label" for="form-stacked-text">Badge</label>
	        			<div class="uk-form-controls">
	            			<input class="uk-input" v-model.lazy="m_badge" id="form-stacked-text" type="text">
	        			</div>
	    			</div>
				</form>

	  			<div slot="footer" class="uk-text-right">
	    			<vk-button class="uk-margin-small-right" @click="show_modal = false">Tühista</vk-button>
	    			<vk-button class="uk-margin-small-right" @click="salvesta(m_pealkiri, m_sisu, m_pilt, m_badge);">Salvesta</vk-button>
	  			</div>
		</vk-modal>
	</div>
</template>

<script>

	import { VueEditor } from "vue2-editor";

	export default {
		data() {
			return {
				show_modal: false,

				m_pealkiri: this.pealkiri,
				m_sisu: this.sisu,
				m_pilt: this.pilt,
				m_badge: this.badge

			}
		},

		components: {
			VueEditor
		},

		methods: {
			salvesta(pealkiri, sisu, pilt, badge) {
				this.show_modal = false
				this.$parent.update_sisu(pealkiri, sisu, pilt, badge);
			},

			onPictureUpload(file) {
				this.m_pilt = file.target.files

				console.log(this.m_pilt)
			}
		},

		props: [
			'id',
			'pealkiri',
			'sisu',
			'pilt',
			'badge'
		]
	}
</script>

<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

.uk-button-green {
	background-color: #3b8222;
	color: white;
}
</style>