<template>
	<div class="sisuhaldus uk-padding-small uk-height" style="height: 100%;">
		<vk-card class="uk-width-1-1@m">
  			<vk-card-title>Sisuhaldus</vk-card-title>
  			<vk-tabs-vertical align="left">
		      <vk-tabs-item title="Pealeht"><pealeht/></vk-tabs-item>
		      <vk-tabs-item title="Lingid"><lingid/></vk-tabs-item>
		      <vk-tabs-item title="Informatsiooni kastid"><infokastid/></vk-tabs-item>
		      <vk-tabs-item title="Galerii"><galerii/></vk-tabs-item>
		    </vk-tabs-vertical>
		</vk-card>
	</div>
</template>

<script>

	import pealeht from '@/components/administraator/sisuhaldus/pealeht.vue'
	import lingid from '@/components/administraator/sisuhaldus/lingid.vue'
	import infokastid from '@/components/administraator/sisuhaldus/informatsioon_kastid.vue'
	import galerii from '@/components/administraator/sisuhaldus/galerii.vue'

	export default {
		components: {
			pealeht,
			lingid,
			infokastid,
			galerii
		}
	}
</script>

<style>
	.sisuhaldus {

	}
</style>