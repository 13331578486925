<template>
	<div class="radimix-kysimuste-nimekiri">
		<h4>{{ o_pealkiri }}</h4>

		<table v-if="loading == false" class="uk-table uk-table-striped">
   			<thead>
        		<tr>
            		<th>Email</th>
            		<th>Telefon</th>
            		<th>Küsimus</th>
            		<th v-if="o_staatus == 0">Valikud</th>
        		</tr>
    		</thead>
    		<tbody>
    			<tr v-for="kysimus in this.kysimused" :key="kysimus['kysimuse_id']">
    				<td v-if="kysimus['staatus'] == o_staatus" width="20%">{{ kysimus['email'] }}</td>
	            	<td v-if="kysimus['staatus'] == o_staatus" width="20%">{{ kysimus['telefon'] }}</td>
	            	<td v-if="kysimus['staatus'] == o_staatus" width="40%">{{ kysimus['kysimus'] }}</td>
	            	<td v-if="o_staatus == 0 && kysimus['staatus'] == 0"> 
	            		<vk-button class="uk-width-1-1 uk-margin uk-button-primary" style="background-color: darkorange;" @click="arhiveeri(kysimus['kysimuse_id'])">Arhiveeri</vk-button>
	            	</td>
    			</tr>
    		</tbody>
    	</table>
		
	</div>
</template>

<script>

	import { getModule } from 'vuex-module-decorators';
  	import LoginModule from '@/store/modules/radimix_login'

	export default {
		data() {
			return {
				o_pealkiri: this.pealkiri,
				o_staatus: this.staatus,
				kysimused: null,
				loading: false
			}
		},

		props: [
			'pealkiri',
			'staatus'
		],

		methods: {
			async arhiveeri(id) {
				this.loading = true
				await getModule(LoginModule, this.$store).arhiveeri_kysimus(id)

				await getModule(LoginModule, this.$store).vaata_kysimusi()
				this.kysimused = getModule(LoginModule, this.$store).kysimused
				this.loading = false
			}
		},

		mounted() {
			this.loading = true
			this.kysimused = getModule(LoginModule, this.$store).kysimused
			this.loading = false
		}
	}
</script>